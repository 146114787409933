<template>
  <div class="pbf">
     
     <TabSubscription
       :data="data"
       />

  </div>
</template>

<script setup>

import TabSubscription from '@/pages/brands-manager/components/TabSubscription'

const props = defineProps({
  data: {
    type: Object    
  }
});

useHead(useNuxtApp().$head.getBrandManager());

/*
import TabSubscription from '@/pages/brands-manager/components/TabSubscription'
  
export default { 
  props: {
    data: {
      type: Object
    }
  },
  components: {     
    TabSubscription
  },
  head() { 
    return this.$head.getBrandManager()
  }
}*/
</script>

<style scoped>



/**/
 
</style>

<style>

</style>